import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import update from "immutability-helper";
import "./common.css";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  CustomInput,
  Button,
  Container,
  Label,
} from "reactstrap";
import { updateProfile } from "../../redux/actions/currentCostingActions";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";
import ReturnControls from "./ReturnControl";
import { useContext } from "react";
import { ChangesTracker } from "./CountryMethodologyTabsFinal";

const Start = ({
  profile,
  indxFC,
  methodology,
  indxSM,
  updateProject,
  showErrorMessages,
  errorFieldsObj,
  isEditable,
  disableFields,
  callFrom,
}) => {
  const checkVariables = useRef();
  checkVariables.current = methodology.OpResourcesVariablesTimecost?.filter(
    (x) => x.check
  );
  // const [checkVariables, setCheckVariables] = useState(
  //   methodology.OpResourcesVariablesTimecost?.filter((x) => x.check)
  // );
  if (methodology.label == "Path Tracking") {
    if (!checkVariables.current[0]?.value) {
      checkVariables.current.map((cv) => {
        cv.value = "ffffff";
      });
    }
  }
  var ischeck = false;
  var cData = "";
  const [modelState, setModalState] = useState(false);
  const [tableDef, setTableDef] = useState(null);
  const [copyOption, setCopyOption] = useState({
    setup: true,
    responsibility: null,
  });
  const [modelStateTableDef, setModalStateTableDef] = useState(false);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const userRecord = useSelector(({ user }) => user.userRecord);
  const [opsResourcesVariables] = useState([
    "surveyProgrammingResource",
    "surveyProgrammingResourceVendor",
    "dataProcessingResource",
    "dataProcessingResourceVendor",
    "dataProcessingComplexity",
    "translationsResource",
    "translationsResourceVendor",
    "verbatimCodingResource",
    "verbatimCodingResourceVendor",
    "chartingResource",
    "chartingResourceVendor",
    "dashboardingResource",
    "dashboardingResourceVendor",
    "qualityControl",
    "qualityControlComments",
    "dataCollectionPlatform",
    "platformComments",
  ]);
  const dispatch = useDispatch();
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );

  const demofn = () => {
    ischeck = true;
  };
  const { hasDataChanged, deleteId } = useContext(ChangesTracker);

  function CopyRFQData() {
    const countrySpecsClone = _.cloneDeep(profile.CountrySpecs);
    const methodologyClone = _.cloneDeep(
      profile.CountrySpecs[indxFC].MethodologySpecs[indxSM]
    );
    countrySpecsClone.forEach((fc, indexFC) => {
      fc.MethodologySpecs.filter(
        (sm, indexSM) =>
          sm.OpResourcesVariablesTimecost.length ==
          methodologyClone.OpResourcesVariablesTimecost.length
      ).forEach((sm, indexSM) => {
        if (indexFC == indxFC && sm.label == methodologyClone.label) return;
        sm.OpResourcesVariablesTimecost.forEach((v, indexV) => {
          if (
            methodologyClone.OpResourcesVariablesTimecost.find(
              (vv) => vv.label == v.label
            )
          ) {
            v.value = _.cloneDeep(
              methodologyClone.OpResourcesVariablesTimecost.filter(
                (vv) => vv.label == v.label
              )[0].value
            );
            v.disabled = _.cloneDeep(
              methodologyClone.OpResourcesVariablesTimecost.filter(
                (vv) => vv.label == v.label
              )[0].disabled
            );
          }
        });
      });
    });

    //new code to copy methodology data also
    if (copyOption.responsibility) {
      countrySpecsClone.forEach((fc, indexFC) => {
        fc.MethodologySpecs.filter(
          (sm, indexSM) =>
            Object.keys(sm.Responsibilities).length ==
              Object.keys(methodology.Responsibilities).length &&
            (indexFC != indxFC || (indexFC == indxFC && indexSM != indxSM))
        ).forEach((sm, indexSM) => {
          sm.Responsibilities = _.cloneDeep(methodology.Responsibilities);
        });
      });
    }
    //new code to copy methodology data also ends

    const profileTemp = update(profile, {
      CountrySpecs: { $set: countrySpecsClone },
    });
    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });
    dispatch(updateProfile(profileTemp));
    dispatch(updateProject({ CostingProfiles: tempProfiles }));
    toastr.success("Success", "Data Copied Successfully");
  }
  const checkValues = (v, label, label2, indexV) => {
    var index = methodology.OpResourcesVariablesTimecost.filter((x) => {
      return x.label == label2;
    })[0]
      .checkOptions.map((z) => {
        return z.label;
      })
      .indexOf(label);

    var element = checkVariables.current.filter((cv) => {
      return cv.label == label2;
    });
    cData = element[0].value;
    let str1 = cData.substr(0, index);
    let str2 = cData.substr(index + 1, cData.length - index);
    let str = "";
    if (v.target.checked) str = str1 + "t" + str2;
    if (!v.target.checked) str = str1 + "f" + str2;

    methodology.OpResourcesVariablesTimecost[
      methodology.OpResourcesVariablesTimecost.map((cv) => {
        return cv.label;
      }).indexOf(label2)
    ].value = str;
    let cVariables = [...checkVariables.current];
    cVariables[
      cVariables
        .map((cv) => {
          return cv.label;
        })
        .indexOf(label2)
    ].value = str;

    checkVariables.current = cVariables;
  };
  const errorStyles = {
    control: (styles) => ({ ...styles, borderColor: "#d9534f" }),
  };
  const ReturnControl = (v, indexV) => {
    return (
      <ReturnControls
        v={v}
        methodology={methodology}
        profile={profile}
        project={project}
        indexV={indexV}
        indxFC={indxFC}
        indxSM={indxSM}
        errorFieldsObj={errorFieldsObj}
        component="SUD"
        InfoChange={InfoChange}
        modelStateTableDef={setModalStateTableDef}
        setTableDef={setTableDef}
        errorStyles={errorStyles}
        disableFields={disableFields}
        callFrom={"timecost"}
      ></ReturnControls>
    );

    // let defTable = v.defTable ? (
    //   <FontAwesomeIcon
    //     title={"click to open definition"}
    //     className="align-middle mr-2 addButton"
    //     icon={faInfoCircle}
    //     onClick={() => {
    //       setModalStateTableDef(true);
    //       setTableDef(v.defTable);
    //     }}
    //     fixedWidth
    //     style={{ cursor: "pointer" }}
    //   />
    // ) : null;
    // if (methodology.SetupSchema?.properties[v.id].defaultValue && !v.value) {
    //   v.value =
    //     v.type === "integer" ||
    //     v.type === "text" ||
    //     v.type === "textarea" ||
    //     v.type === "multi" ||
    //     v.type === "date"
    //       ? methodology.SetupSchema.properties[v.id].defaultValue
    //       : v.type === "single"
    //       ? methodology.SetupSchema.properties[v.id].defaultValue.value
    //       : v.value;
    //   if (v.value) InfoChange(indexV, v.value);
    // }
    // if (v.type == "integer") {
    //   const isNumberOfWaves = v.id === "numberOfWaves";
    //   return (
    //     <FormGroup>
    //       <Label>
    //         {v.label + (v.mandatory ? "(*)" : "")}
    //         {v.tooltext && (
    //           <FontAwesomeIcon
    //             title={v.tooltext}
    //             className="align-middle mr-2 addButton"
    //             icon={faInfoCircle}
    //             fixedWidth
    //             style={{ cursor: "pointer" }}
    //           />
    //         )}
    //         {defTable}
    //       </Label>
    //       <AvField
    //         disabled={
    //           profile.ProfileStatus == 99 ||
    //           (_.includes(opsResourcesVariables, v.id) &&
    //             !userRecord.CanEditOpsResources)
    //         }
    //         name={"field_" + indexV}
    //         placeholder=""
    //         type="number"
    //         min={isNumberOfWaves ? 2 : null}
    //         value={v.value || ""}
    //         errorMessage={
    //           isNumberOfWaves && v.value < 2
    //             ? "Number of waves must be more than 1 since it's tracker."
    //             : "field is required"
    //         }
    //         validate={
    //           v.mandatory &&
    //           !methodology.NotApplicable &&
    //           (!_.includes(opsResourcesVariables, v.id) ||
    //             (_.includes(opsResourcesVariables, v.id) &&
    //               userRecord.CanEditOpsResources))
    //             ? { required: { value: v.mandatory }, min: { value: 0 } }
    //             : isNumberOfWaves && v.value < 2
    //             ? { required: { value: true }, min: { value: 2 } }
    //             : {}
    //         }
    //         onChange={(e) => InfoChange(indexV, e.target.value)}
    //         isdisabled={
    //           profile.ProfileStatus == 99 ||
    //           methodology.NotApplicable ||
    //           disableFields
    //         }
    //       />
    //     </FormGroup>
    //   );
    // } else if (v.type == "single")
    //   return (
    //     <FormGroup>
    //       <Label>
    //         {v.label + (v.mandatory ? "(*)" : "")}
    //         {v.tooltext && (
    //           <FontAwesomeIcon
    //             title={v.tooltext}
    //             className="align-middle mr-2 addButton"
    //             icon={faInfoCircle}
    //             fixedWidth
    //             style={{ cursor: "pointer" }}
    //           />
    //         )}
    //         {defTable}
    //       </Label>
    //       <AvField
    //         disabled={
    //           profile.ProfileStatus == 99 ||
    //           (_.includes(opsResourcesVariables, v.id) &&
    //             !userRecord.CanEditOpsResources) ||
    //           methodology.NotApplicable ||
    //           disableFields
    //         }
    //         tag={CustomInput}
    //         name={v.label}
    //         value={v.value}
    //         type="select"
    //         placeholder="please select"
    //         errorMessage="field is required"
    //         validate={
    //           v.mandatory &&
    //           !methodology.NotApplicable &&
    //           (!_.includes(opsResourcesVariables, v.id) ||
    //             (_.includes(opsResourcesVariables, v.id) &&
    //               userRecord.CanEditOpsResources))
    //             ? { required: { value: v.mandatory } }
    //             : {}
    //         }
    //         clearable
    //         onChange={(e) => {
    //           InfoChange(indexV, e.target.value);
    //         }}
    //       >
    //         <option value="0"></option>
    //         {v.optionsLabel
    //           ? codeLabels[v.optionsLabel].map((opt) => (
    //               <option key={opt.Code} value={opt.Code}>
    //                 {opt.Label}
    //               </option>
    //             ))
    //           : v.options?.map((option) => (
    //               <option key={option.value} value={option.value}>
    //                 {option.label}
    //               </option>
    //             ))}
    //       </AvField>
    //     </FormGroup>
    //   );
    // else if (
    //   v.type == "multi" &&
    //   codeLabels[v.optionsLabel] &&
    //   codeLabels[v.optionsLabel].find((x) => x.Label.toLowerCase() == "others")
    // ) {
    //   return (
    //     <FormGroup>
    //       <Label>
    //         {v.label + (v.mandatory ? "(*)" : "")}
    //         {v.tooltext && (
    //           <FontAwesomeIcon
    //             title={v.tooltext}
    //             className="align-middle mr-2 addButton"
    //             icon={faInfoCircle}
    //             fixedWidth
    //             style={{ cursor: "pointer" }}
    //           />
    //         )}
    //         {defTable}
    //       </Label>
    //       <CreatableSelect
    //         isDisabled={
    //           profile.ProfileStatus == 99 ||
    //           (_.includes(opsResourcesVariables, v.id) &&
    //             !userRecord.CanEditOpsResources) ||
    //           methodology.NotApplicable ||
    //           disableFields
    //         }
    //         name={v.label}
    //         value={v.value}
    //         styles={
    //           errorFieldsObj &&
    //           errorFieldsObj[profile.CountrySpecs[indxFC].CountryName] &&
    //           errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"] &&
    //           errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][
    //             profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label
    //           ] &&
    //           _.includes(
    //             errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][
    //               profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label
    //             ],
    //             v.label
    //           )
    //             ? errorStyles
    //             : ""
    //         }
    //         onChange={(e) => InfoChange(indexV, e)}
    //         className="react-select-container"
    //         classNamePrefix="react-select"
    //         validate={
    //           v.mandatory &&
    //           !methodology.NotApplicable &&
    //           (!_.includes(opsResourcesVariables, v.id) ||
    //             (_.includes(opsResourcesVariables, v.id) &&
    //               userRecord.CanEditOpsResources))
    //             ? { required: { value: v.mandatory } }
    //             : {}
    //         }
    //         options={
    //           v.optionsLabel
    //             ? codeLabels[v.optionsLabel].map((opt) => {
    //                 return {
    //                   value: opt.Code,
    //                   label: opt.Label,
    //                 };
    //               })
    //             : v.options?.map((option) => {
    //                 return {
    //                   value: option.value,
    //                   label: option.label,
    //                 };
    //               })
    //         }
    //         isMulti
    //       />
    //       {errorFieldsObj &&
    //       errorFieldsObj[profile.CountrySpecs[indxFC].CountryName] &&
    //       errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"] &&
    //       errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][
    //         profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label
    //       ] &&
    //       _.includes(
    //         errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][
    //           profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label
    //         ],
    //         v.label
    //       ) ? (
    //         <p className="invalid-feedback d-flex">field is required</p>
    //       ) : null}
    //     </FormGroup>
    //   );
    // } else if (v.type == "multi")
    //   return (
    //     <FormGroup>
    //       <Label>
    //         {v.label + (v.mandatory ? "(*)" : "")}
    //         {v.tooltext && (
    //           <FontAwesomeIcon
    //             title={v.tooltext}
    //             className="align-middle mr-2 addButton"
    //             icon={faInfoCircle}
    //             fixedWidth
    //             style={{ cursor: "pointer" }}
    //           />
    //         )}
    //         {defTable}
    //       </Label>

    //       <Select
    //         isDisabled={
    //           profile.ProfileStatus == 99 ||
    //           (_.includes(opsResourcesVariables, v.id) &&
    //             !userRecord.CanEditOpsResources) ||
    //           methodology.NotApplicable ||
    //           disableFields
    //         }
    //         name={v.label}
    //         value={v.value}
    //         styles={
    //           errorFieldsObj &&
    //           errorFieldsObj[profile.CountrySpecs[indxFC].CountryName] &&
    //           errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"] &&
    //           errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][
    //             profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label
    //           ] &&
    //           _.includes(
    //             errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][
    //               profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label
    //             ],
    //             v.label
    //           )
    //             ? errorStyles
    //             : ""
    //         }
    //         onChange={(e) => InfoChange(indexV, e)}
    //         className="react-select-container"
    //         classNamePrefix="react-select"
    //         validate={
    //           v.mandatory &&
    //           !methodology.NotApplicable &&
    //           (!_.includes(opsResourcesVariables, v.id) ||
    //             (_.includes(opsResourcesVariables, v.id) &&
    //               userRecord.CanEditOpsResources))
    //             ? { required: { value: v.mandatory } }
    //             : {}
    //         }
    //         options={
    //           v.optionsLabel
    //             ? codeLabels[v.optionsLabel].map((opt) => {
    //                 return {
    //                   value: opt.Code,
    //                   label: opt.Label,
    //                 };
    //               })
    //             : v.options?.map((option) => {
    //                 return {
    //                   value: option.value,
    //                   label: option.label,
    //                 };
    //               })
    //         }
    //         isMulti
    //       />
    //       {errorFieldsObj &&
    //       errorFieldsObj[profile.CountrySpecs[indxFC].CountryName] &&
    //       errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"] &&
    //       errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][
    //         profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label
    //       ] &&
    //       _.includes(
    //         errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][
    //           profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label
    //         ],
    //         v.label
    //       ) ? (
    //         <p className="invalid-feedback d-flex">field is required</p>
    //       ) : null}
    //     </FormGroup>
    //   );
    // else if (v.type == "text")
    //   return (
    //     <FormGroup>
    //       <Label>
    //         {v.label + (v.mandatory ? "(*)" : "")}
    //         {v.tooltext && (
    //           <FontAwesomeIcon
    //             title={v.tooltext}
    //             className="align-middle mr-2 addButton"
    //             icon={faInfoCircle}
    //             fixedWidth
    //             style={{ cursor: "pointer" }}
    //           />
    //         )}
    //         {defTable}
    //       </Label>
    //       <AvField
    //         disabled={
    //           profile.ProfileStatus == 99 ||
    //           (_.includes(opsResourcesVariables, v.id) &&
    //             !userRecord.CanEditOpsResources) ||
    //           methodology.NotApplicable ||
    //           disableFields
    //         }
    //         name={"field_" + indexV}
    //         placeholder=""
    //         type="text"
    //         errorMessage="field is required"
    //         validate={
    //           v.mandatory &&
    //           !methodology.NotApplicable &&
    //           (!_.includes(opsResourcesVariables, v.id) ||
    //             (_.includes(opsResourcesVariables, v.id) &&
    //               userRecord.CanEditOpsResources))
    //             ? { required: { value: v.mandatory } }
    //             : {}
    //         }
    //         onChange={(e) => InfoChange(indexV, e.target.value)}
    //         value={v.value || ""}
    //       />
    //     </FormGroup>
    //   );
    // else if (v.type == "textarea")
    //   return (
    //     <FormGroup>
    //       <Label>
    //         {v.label + (v.mandatory ? "(*)" : "")}
    //         {v.tooltext && (
    //           <FontAwesomeIcon
    //             title={v.tooltext}
    //             className="align-middle mr-2 addButton"
    //             icon={faInfoCircle}
    //             fixedWidth
    //             style={{ cursor: "pointer" }}
    //           />
    //         )}
    //         {defTable}
    //       </Label>
    //       <AvField
    //         disabled={
    //           profile.ProfileStatus == 99 ||
    //           (_.includes(opsResourcesVariables, v.id) &&
    //             !userRecord.CanEditOpsResources) ||
    //           methodology.NotApplicable ||
    //           disableFields
    //         }
    //         name={"field_" + indexV}
    //         placeholder=""
    //         type="textarea"
    //         errorMessage="field is required"
    //         validate={
    //           v.mandatory &&
    //           !methodology.NotApplicable &&
    //           (!_.includes(opsResourcesVariables, v.id) ||
    //             (_.includes(opsResourcesVariables, v.id) &&
    //               userRecord.CanEditOpsResources))
    //             ? { required: { value: v.mandatory } }
    //             : {}
    //         }
    //         onChange={(e) => InfoChange(indexV, e.target.value)}
    //         value={v.value || ""}
    //       />
    //     </FormGroup>
    //   );
    // else if (v.type == "date")
    //   return (
    //     <FormGroup>
    //       <Label>
    //         {v.label + (v.mandatory ? "(*)" : "")}
    //         {v.tooltext && (
    //           <FontAwesomeIcon
    //             title={v.tooltext}
    //             className="align-middle mr-2 addButton"
    //             icon={faInfoCircle}
    //             fixedWidth
    //             style={{ cursor: "pointer" }}
    //           />
    //         )}
    //         {defTable}
    //       </Label>
    //       <AvField
    //         disabled={
    //           profile.ProfileStatus == 99 ||
    //           (_.includes(opsResourcesVariables, v.id) &&
    //             !userRecord.CanEditOpsResources) ||
    //           methodology.NotApplicable ||
    //           disableFields
    //         }
    //         name={"field_" + indexV}
    //         placeholder=""
    //         type="date"
    //         errorMessage="field is required"
    //         validate={
    //           v.mandatory &&
    //           !methodology.NotApplicable &&
    //           (!_.includes(opsResourcesVariables, v.id) ||
    //             (_.includes(opsResourcesVariables, v.id) &&
    //               userRecord.CanEditOpsResources))
    //             ? { required: { value: v.mandatory } }
    //             : {}
    //         }
    //         onChange={(e) => InfoChange(indexV, e.target.value)}
    //         value={v.value || ""}
    //       />
    //     </FormGroup>
    //   );
    // else if (v.type == "header")
    //   return (
    //     <>
    //       <h5 id={"header_" + indexV} className={"header"}>
    //         {v.label}
    //       </h5>
    //       {v.instructions && (
    //         <h6 id={"header_i_" + indexV}>{v.instructions}</h6>
    //       )}
    //     </>
    // );
  };

  function InfoChange(indexV, value) {
    const variablesClone = _.cloneDeep(
      profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].OpResourcesVariablesTimecost
    );
    const OpResourcesData = _.cloneDeep(
      profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].SetupData
    );
    const currentVariable = variablesClone[indexV];
    currentVariable["value"] = value;
    var counter = 0;

    variablesClone.forEach((v, indexV) => {
      if (
        v.dependentOn &&
        v.dependentOn.fields.find((f) => f.fieldName == currentVariable.label)
      ) {
        if (v.label == "Approx Percentage Change - Each Wave") {
        }

        let op = v.dependentOn.andor == "OR" ? "||" : "&&";
        let expression = "";
        v.dependentOn.fields.forEach((f, indexF) => {
          let field =
            f.fieldName != currentVariable.label
              ? variablesClone.filter((v) => v.label == f.fieldName)[0]
              : currentVariable;
          let fieldVal = field ? field.value : null;
          if (Array.isArray(fieldVal)) {
            let tempBool =
              variablesClone
                .filter((v) => v.label == f.fieldName)[0]
                ["value"].filter((x) => x.label == f.fieldValue).length > 0;
            expression +=
              tempBool +
              f.operator +
              "true" +
              (indexF != v.dependentOn.fields.length - 1 ? op : "");
          } else {
            expression +=
              getChangedValue(fieldVal) +
              f.operator +
              getChangedValue(f.fieldValue) +
              (indexF != v.dependentOn.fields.length - 1 ? op : "");
          }
        });

        let enabled = eval(expression);
        if (enabled) {
          v.disabled = false;
          //custom code starts
          if (v.label == "Approx Percentage Change - Each Wave") {
            if (!v.enabled) {
              v.disabled = true;
            }
          }
          //custom code ends
        } else {
          v.disabled = true;
          v.value = null;
          deleteId(v.id, indxFC, indxSM);
          variablesClone.forEach((v2, indexV2) => {
            if (
              v2.dependentOn &&
              v2.dependentOn.fields.find((f) => f.fieldName == v.label)
            ) {
              v2.disabled = true;
              v2.value = null;
            }
          });
        }
      }
      if (v.id == "surveyProgrammingRequired") {
        if (v.value != "Yes") {
          if (
            variablesClone.filter(
              (tv) => tv.id == "surveyProgrammingResource"
            )[0]
          ) {
            variablesClone.filter(
              (tv) => tv.id == "surveyProgrammingResource"
            )[0].disabled = true;
          }
        } else {
          if (
            variablesClone.filter(
              (tv) => tv.id == "surveyProgrammingResource"
            )[0]
          ) {
            variablesClone.filter(
              (tv) => tv.id == "surveyProgrammingResource"
            )[0].disabled = false;
          }
        }
      }
      let opMaster = {
        surveyProgrammingResource: "surveyProgrammingRequired",
        dataProcessingResource: "dataProcessingRequired",
        translationsResource: "translations",
        verbatimCodingResourc: "verbatimCoding",
        chartingResource: "chartingRequired",
        dashboardingResource: "dashboarding",
      };

      // to set the section header elemment for opeation resources section
      if (v.sectionTitle == "Operations Resources") {
        if (
          counter == 0 &&
          ((_.includes(Object.keys(opMaster), v.id) && !v.disabled) ||
            variablesClone.find((tv) => tv.id == opMaster[v.id])?.value ==
              "Yes")
        ) {
          v.isOps = true;
          v.isNewSection = true;
          v.sectionTitle = "Operations Resources";
          counter++;
        } else if (v.id == "qualityControl" && counter == 0) {
          v.isOps = true;
          v.isNewSection = true;
          v.sectionTitle = "Operations Resources";
          counter++;
        } else {
          v.isOps = true;
          v.isNewSection = false;
        }
      }
    });
    const hasChanged = hasDataChanged(
      OpResourcesData,
      variablesClone[indexV],
      indxFC,
      indxSM
    );
    isEditable(hasChanged);
    const profileTemp = update(profile, {
      CountrySpecs: {
        [indxFC]: {
          MethodologySpecs: {
            [indxSM]: {
              OpResourcesVariablesTimecost: { $set: variablesClone },
            },
          },
        },
      },
    });

    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });
    dispatch(updateProfile(_.cloneDeep(profileTemp)));
    dispatch(updateProject({ CostingProfiles: tempProfiles }));
  }

  function getChangedValue(value) {
    let rValue = null;
    if (isNaN(value)) rValue = "'" + value + "'";
    else rValue = value || 0;
    return rValue;
  }

  // methodology.OpResourcesVariablesTimecost = Object.values(methodology.OpsTimecostSchema.properties).map((ots) => {
  //   return {...ots, label: ots.title}
  // })

  console.log(methodology.OpResourcesVariablesTimecost, "uauua");

  return (
    <React.Fragment>
      <Container
        style={{
          marginLeft: "100px",
          backgroundColor: "white",
          width: "100%"
        }}
      >
        {methodology.OpResourcesVariablesTimecost?.length > 0 && (
          <Card className="ml-2 mr-2">
            {callFrom !== "timecost" && (
              <CardHeader>
                <Row>
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CardTitle id="header_op_r" className="mb-0">
                      Setup & Delivery
                    </CardTitle>
                    {profile.CountrySpecs &&
                      profile.CountrySpecs.length > 1 && (
                        <FontAwesomeIcon
                          title="Copy Data To Other Markets"
                          className="align-middle mr-2 addButton"
                          icon={!disableFields ? faCopy : ""}
                          fixedWidth
                          onClick={() => {
                            setModalState(true);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                  </Col>
                </Row>
              </CardHeader>
            )}
            <CardBody>
              <Row>
                <Col md={12} className={""}>
                  <div className={"tab"}>
                    <Container className="p-1">
                      <AvForm
                        encType="multipart/form-data"
                        id="OpsResourcesFinalForm"
                        onSubmit={(e, err) =>
                          showErrorMessages(e, err, "OpsformErrors")
                        }
                      >
                        {methodology?.OpsTimecostSchema?.order.length?<Row>
                         <Col md={"11"}>
                          <h5
                            id={"header_ops"}
                            className={"header"}
                          >
                            Operations Resources
                          </h5>
                        </Col>
                        </Row>:null}
                        <Row>                          
                          {methodology.OpResourcesVariablesTimecost &&
                            methodology.OpResourcesVariablesTimecost.map(
                              (v, indexV) => {
                                if (v.sectionTitle === "Operations Resources") {
                                  if (
                                    !v.Default &&
                                    !v.selected &&
                                    !v.disabled &&
                                    v.isNewSection
                                  )
                                    return (
                                      <>
                                        {/* <Col md={"11"}>
                                          <h5
                                            id={"header_" + indexV}
                                            className={"header"}
                                          >
                                            {v.sectionTitle}
                                          </h5>
                                        </Col> */}
                                        {v.instructions && (
                                          <Col md={"11"}>
                                            <h6 id={"header_" + indexV}>
                                              {" "}
                                              {v.instructions}
                                            </h6>
                                          </Col>
                                        )}
                                        <Col
                                          md={v.width || "4"}
                                          key={
                                            "c_" +
                                            indxFC +
                                            "_m_" +
                                            indxSM +
                                            "_co_" +
                                            indexV
                                          }
                                        >
                                          {ReturnControl(v, indexV)}
                                        </Col>
                                      </>
                                    );
                                  else if (
                                    (v.selected || v.Default) &&
                                    v.isNewSection &&
                                    methodology.OpResourcesVariablesTimecost?.find(
                                      (opr) =>
                                        !opr.selected &&
                                        !opr.disabled &&
                                        opr.sectionTitle == v.sectionTitle
                                    )
                                  )
                                    return (
                                      <Col md={"11"}>
                                        {/* <h5
                                          id={"header_" + indexV}
                                          className={"header"}
                                        >
                                          {v.sectionTitle}
                                        </h5> */}
                                        {v.instructions && (
                                          <Col md={"11"}>
                                            <h6 id={"header_" + indexV}>
                                              {" "}
                                              {v.instructions}
                                            </h6>
                                          </Col>
                                        )}
                                      </Col>
                                    );
                                  else if (
                                    !v.selected &&
                                    !v.disabled &&
                                    !v.Default &&
                                    !v.isNewSection
                                  )
                                    return (
                                      <>
                                      <Col
                                        md={v.width || "4"}
                                        key={
                                          "c_" +
                                          indxFC +
                                          "_m_" +
                                          indxSM +
                                          "_co_" +
                                          indexV
                                        }
                                      >
                                        {ReturnControl(v, indexV)}
                                      </Col>
                                      </>
                                    );
                                  else if (v.check && !ischeck) {
                                    demofn();

                                    return (
                                      <Table
                                        size="sm"
                                        bordered
                                        style={{
                                          backgroundColor: "white",
                                          width: "100%",
                                        }}
                                      >
                                        <thead>
                                          <tr>
                                            {" "}
                                            <td md={"4"}>
                                              <b>Type Of Interactions</b>
                                            </td>
                                            {checkVariables.current[0].checkOptions.map(
                                              (co) => {
                                                return (
                                                  <td>
                                                    <b>{co.label}</b>
                                                  </td>
                                                );
                                              }
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {checkVariables.current.map(
                                            (v, index) => {
                                              return (
                                                <tr>
                                                  <td md={"4"}>{v.label}</td>
                                                  {v.checkOptions.map(
                                                    (co, coIndex) => {
                                                      return (
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            name={v.label}
                                                            checked={
                                                              checkVariables
                                                                .current[index]
                                                                .value[
                                                                coIndex
                                                              ] == "f"
                                                                ? false
                                                                : true
                                                            }
                                                            onChange={(e) =>
                                                              checkValues(
                                                                e,
                                                                co.label,
                                                                v.label,
                                                                indexV
                                                              )
                                                            }
                                                          ></input>
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    );
                                  }
                                }
                              }
                            )}
                        </Row>
                      </AvForm>
                    </Container>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Container>
      <Modal
        isOpen={modelState}
        toggle={() => setModalState(!modelState)}
        centered
        size={"xm"}
      >
        <ModalHeader toggle={() => setModalState(!modelState)}>
          {"Copying RFQ data..."}
        </ModalHeader>
        <ModalBody className="m-3" style={{ minHeight: "60px" }}>
          <Row>
            <Col md={12}>
              <h4>
                Do you want to copy Setup & Delivery data to other applicable
                markets & similar methodologies?
              </h4>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <input
                disabled
                name={"copySetup"}
                checked={true}
                type="checkbox"
                onChange={(e) => {
                  setCopyOption({
                    ...copyOption,
                    setup: e.target.checked,
                  });
                }}
              ></input>
              <span style={{ marginLeft: "10px" }}>Copy Setup & Delivery</span>
            </Col>
          </Row>
          {profile.CountrySpecs && profile.CountrySpecs.length > 1 && (
            <Row>
              <Col md={12}>
                <input
                  name={"copyResponsibility"}
                  checked={copyOption["responsibility"]}
                  type="checkbox"
                  onChange={(e) => {
                    setCopyOption({
                      ...copyOption,
                      responsibility: e.target.checked,
                    });
                  }}
                ></input>
                <span style={{ marginLeft: "10px" }}>
                  Copy Responsibilities
                </span>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              CopyRFQData();
              setModalState(false);
              setCopyOption({
                ...copyOption,
                responsibility: false,
              });
            }}
          >
            Yes
          </Button>
          <Button
            color="danger"
            onClick={() => {
              setModalState(false);
              setModalStateTableDef(true);
              setCopyOption({
                ...copyOption,
                responsibility: false,
              });
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>

      {tableDef && (
        <Modal
          isOpen={modelStateTableDef}
          toggle={() => setModalStateTableDef(!modelStateTableDef)}
          centered
          size={"lg"}
        >
          <ModalHeader toggle={() => setModalStateTableDef(false)}>
            {"Definitions"}
          </ModalHeader>
          <ModalBody className="m-3" style={{ minHeight: "60px" }}>
            <Row>
              <Col
                md={12}
                className={"tableCopyProject costingOptions tableDef"}
              >
                <Table
                  size="xl"
                  bordered
                  style={{
                    backgroundColor: "white",
                    maxWidth: "100%",
                    width: "auto",
                  }}
                >
                  <thead>
                    {tableDef.headers.map((header, indexH) => (
                      <tr key={indexH}>
                        {header.columns.map((th, indexC) => (
                          <th
                            colSpan={th.colSpan}
                            rowSpan={th.rowSpan}
                            key={indexH + "_" + indexC}
                            style={{ minWidth: "250px", maxWidth: "250px" }}
                          >
                            {th.label}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {tableDef.rows.map((tr, indexR) => (
                      <tr key={indexR}>
                        {tr.columns.map((td, indexC) => (
                          <td
                            colSpan={td.colSpan}
                            rowSpan={td.rowSpan}
                            key={indexR + "_" + indexC}
                          >
                            {td.label}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => setModalStateTableDef(false)}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default Start;