import React, { useState, useEffect, useSelector } from 'react';
import { Button, Card, CardBody, CardTitle, Form, FormGroup, Label, Input, Row, Col, CardText, CardImg } from 'reactstrap';
import { connect } from 'react-redux';
import * as userActions from '../../redux/actions/userActions'; // Adjust the import path as needed
import AuthLayout from '../../layouts/Auth'; // Adjust the import path as needed
import { Link } from 'react-router-dom';
import { encryptOrDecryptString} from  '../../utils/azureConstant';
import image from "../../assets/img/avatars/niq.png";
import Select from "react-select";
import { Bold } from 'react-feather';
 
const Register = (props) => {
  let RoleOption = [
    {
      "Label": "Commercial"
    },
    {
       "Label": "Costing SPOC"
    },
    {
       "Label": "Project Management"
    },
    {
      "Label": "Approver"
   }
  ]; 
  let CommissioningCountriesOptions =  [
    {
      "id": 3,
      "Code": "DZ",
      "Label": "Algeria"
    },
    {
      "id": 7,
      "Code": "AR",
      "Label": "Argentina"
    },
    {
      "id": 9,
      "Code": "AU",
      "Label": "Australia"
    },
    {
      "id": 10,
      "Code": "AT",
      "Label": "Austria"
    },
    {
      "id": 13,
      "Code": "BH",
      "Label": "Bahrain"
    },
    {
      "id": 14,
      "Code": "BD",
      "Label": "Bangladesh"
    },
    {
      "id": 16,
      "Code": "BY",
      "Label": "Belarus"
    },
    {
      "id": 17,
      "Code": "BE",
      "Label": "Belgium"
    },
    {
      "id": 22,
      "Code": "BA",
      "Label": "Bosnia"
    },
    {
      "id": 24,
      "Code": "BR",
      "Label": "Brazil"
    },
    {
      "id": 26,
      "Code": "BG",
      "Label": "Bulgaria"
    },
    {
      "id": 31,
      "Code": "CA",
      "Label": "Canada"
    },
    {
      "id": 35,
      "Code": "CL",
      "Label": "Chile"
    },
    {
      "id": 36,
      "Code": "CN",
      "Label": "China"
    },
    {
      "id": 37,
      "Code": "CO",
      "Label": "Colombia"
    },
    {
      "id": 41,
      "Code": "CR",
      "Label": "Costa Rica"
    },
    {
      "id": 42,
      "Code": "HR",
      "Label": "Croatia"
    },
    {
      "id": 45,
      "Code": "CZ",
      "Label": "Czech Republic"
    },
    {
      "id": 46,
      "Code": "DK",
      "Label": "Denmark"
    },
    {
      "id": 48,
      "Code": "DO",
      "Label": "Dominican Republic"
    },
    {
      "id": 50,
      "Code": "EC",
      "Label": "Ecuador"
    },
    {
      "id": 51,
      "Code": "EG",
      "Label": "Egypt"
    },
    {
      "id": 52,
      "Code": "SV",
      "Label": "El Salvador"
    },
    {
      "id": 55,
      "Code": "EE",
      "Label": "Estonia"
    },
    {
      "id": 58,
      "Code": "FI",
      "Label": "Finland"
    },
    {
      "id": 59,
      "Code": "FR",
      "Label": "France"
    },
    {
      "id": 63,
      "Code": "DE",
      "Label": "Germany"
    },
    {
      "id": 65,
      "Code": "GR",
      "Label": "Greece"
    },
    {
      "id": 67,
      "Code": "GT",
      "Label": "Guatemala"
    },
    {
      "id": 72,
      "Code": "HN",
      "Label": "Honduras"
    },
    {
      "id": 73,
      "Code": "HK",
      "Label": "Hong Kong"
    },
    {
      "id": 74,
      "Code": "HU",
      "Label": "Hungary"
    },
    {
      "id": 76,
      "Code": "IN",
      "Label": "India"
    },
    {
      "id": 77,
      "Code": "ID",
      "Label": "Indonesia"
    },
    {
      "id": 80,
      "Code": "IE",
      "Label": "Ireland"
    },
    {
      "id": 82,
      "Code": "IT",
      "Label": "Italy"
    },
    {
      "id": 85,
      "Code": "JP",
      "Label": "Japan"
    },
    {
      "id": 86,
      "Code": "JO",
      "Label": "Jordan"
    },
    {
      "id": 87,
      "Code": "KZ",
      "Label": "Kazakhstan"
    },
    {
      "id": 88,
      "Code": "KE",
      "Label": "Kenya"
    },
    {
      "id": 91,
      "Code": "KR",
      "Label": "South Korea"
    },
    {
      "id": 93,
      "Code": "KW",
      "Label": "Kuwait"
    },
    {
      "id": 96,
      "Code": "LV",
      "Label": "Latvia"
    },
    {
      "id": 97,
      "Code": "LB",
      "Label": "Lebanon"
    },
    {
      "id": 102,
      "Code": "LT",
      "Label": "Lithuania"
    },
    {
      "id": 107,
      "Code": "MY",
      "Label": "Malaysia"
    },
    {
      "id": 114,
      "Code": "MX",
      "Label": "Mexico"
    },
    {
      "id": 118,
      "Code": "ME",
      "Label": "Montenegro"
    },
    {
      "id": 119,
      "Code": "MA",
      "Label": "Morocco"
    },
    {
      "id": 121,
      "Code": "MM",
      "Label": "Myanmar"
    },
    {
      "id": 124,
      "Code": "NP",
      "Label": "Nepal"
    },
    {
      "id": 125,
      "Code": "NL",
      "Label": "Netherlands"
    },
    {
      "id": 126,
      "Code": "NZ",
      "Label": "New Zealand"
    },
    {
      "id": 127,
      "Code": "NI",
      "Label": "Nicaragua"
    },
    {
      "id": 129,
      "Code": "NG",
      "Label": "Nigeria"
    },
    {
      "id": 130,
      "Code": "NO",
      "Label": "Norway"
    },
    {
      "id": 131,
      "Code": "OM",
      "Label": "Oman"
    },
    {
      "id": 132,
      "Code": "PK",
      "Label": "Pakistan"
    },
    {
      "id": 135,
      "Code": "PA",
      "Label": "Panama"
    },
    {
      "id": 137,
      "Code": "PY",
      "Label": "Paraguay"
    },
    {
      "id": 138,
      "Code": "PE",
      "Label": "Peru"
    },
    {
      "id": 139,
      "Code": "PH",
      "Label": "Philippines"
    },
    {
      "id": 140,
      "Code": "PL",
      "Label": "Poland"
    },
    {
      "id": 141,
      "Code": "PT",
      "Label": "Portugal"
    },
    {
      "id": 142,
      "Code": "QA",
      "Label": "Qatar"
    },
    {
      "id": 143,
      "Code": "RO",
      "Label": "Romania"
    },
    {
      "id": 144,
      "Code": "RU",
      "Label": "Russia"
    },
    {
      "id": 152,
      "Code": "SA",
      "Label": "Saudi Arabia"
    },
    {
      "id": 154,
      "Code": "RS",
      "Label": "Serbia"
    },
    {
      "id": 157,
      "Code": "SG",
      "Label": "Singapore"
    },
    {
      "id": 158,
      "Code": "SK",
      "Label": "Slovakia"
    },
    {
      "id": 159,
      "Code": "SI",
      "Label": "Slovenia"
    },
    {
      "id": 162,
      "Code": "ZA",
      "Label": "South Africa"
    },
    {
      "id": 164,
      "Code": "ES",
      "Label": "Spain"
    },
    {
      "id": 165,
      "Code": "LK",
      "Label": "Sri Lanka"
    },
    {
      "id": 169,
      "Code": "SE",
      "Label": "Sweden"
    },
    {
      "id": 170,
      "Code": "CH",
      "Label": "Switzerland"
    },
    {
      "id": 172,
      "Code": "TW",
      "Label": "Taiwan"
    },
    {
      "id": 174,
      "Code": "TZ",
      "Label": "Tanzania"
    },
    {
      "id": 175,
      "Code": "TH",
      "Label": "Thailand"
    },
    {
      "id": 179,
      "Code": "TN",
      "Label": "Tunisia"
    },
    {
      "id": 180,
      "Code": "TR",
      "Label": "Turkey"
    },
    {
      "id": 183,
      "Code": "UG",
      "Label": "Uganda"
    },
    {
      "id": 184,
      "Code": "UA",
      "Label": "Ukraine"
    },
    {
      "id": 185,
      "Code": "AE",
      "Label": "United Arab Emirates"
    },
    {
      "id": 186,
      "Code": "GB",
      "Label": "United Kingdom"
    },
    {
      "id": 187,
      "Code": "US",
      "Label": "United States"
    },
    {
      "id": 188,
      "Code": "UY",
      "Label": "Uruguay"
    },
    {
      "id": 191,
      "Code": "VE",
      "Label": "Venezuela"
    },
    {
      "id": 192,
      "Code": "VN",
      "Label": "Vietnam"
    },
    {
      "id": 201,
      "Code": "PR",
      "Label": "Puerto Rico"
    }
  ]
  const [registerData, setRegisterData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    Comments: '',
    Part1Confirmed: '',
    Part2Confirmed: '',
    UnderstandingConfirmed: '',
    Role: [],
    Country: [],
    Clarification: '',
  });
  const onRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const onRegisterChangeCountry = (selectedOptions) => {
    // Check if the value is an array (since it's multi-select)
   console.log("selectedOptions", selectedOptions);
    setRegisterData(({
      ...registerData,
      Country:selectedOptions,
    }));
  };
  const onRegisterChangeRole = (selectedOptions) => {
    // Check if the value is an array (since it's multi-select)
   console.log("selectedOptions", selectedOptions);
    setRegisterData(({
      ...registerData,
      Role:selectedOptions,
    }));
  };
  // const onRegisterChangeRoleandCountry = (e) => {
  //   const selectedValues = Array.isArray(selectedOptions) ? selectedOptions.map(option => option.value) : selectedOptions.value;
 
  //   setRegisterData((prevState) => ({
  //     ...prevState,
  //     [name]: selectedValues,
  //   }));
  // };
  // Custom Styles for the radio button
  const radioStyle = {
    position: 'relative',
	transform: 'scale(1.4)',
	marginRight: '10px',
  };
 
  const radioContainerStyle = {
    display: 'flex',
    alignItems: 'center',
	marginLeft: "40px",
  };
 
  const customRadioLabelStyle = {
    fontSize: '1.0rem',
    display: 'flex',
    alignItems: 'center',
  };
  // const onRegisterSubmitHandler = (e) => {
  //   e.preventDefault();
  //   let data = registerData;
  //   data.Email = data.Email.trim().toLowerCase();
  //   props.onRegister(data);
  // };
  const onRegisterSubmitHandler = (e) => {
    e.preventDefault();
 
    let formData = {};
    let query;
 
    // Capture question 1 response
    formData["Watch the video CINTRAL Training Part1"] =
        registerData.Part1Confirmed === 'confirmed'
        ? "I confirm that I have watched the part 1 video."
        : "I need further clarification before moving forward";
 
    // Capture question 2 response (only if Question 1 is confirmed)
    if (registerData.Part1Confirmed === 'confirmed') {
        formData["Watch the video CINTRAL Training Part2"] =
            registerData.Part2Confirmed === 'confirmed'
            ? "I confirm that I have watched the part 2 video."
            : "I need further clarification before moving forward";
    }
    if (registerData.Part1Confirmed === 'confirmed' && registerData.Part2Confirmed === 'confirmed') {
      formData["Please confirm that you understand the content"] =
          registerData.UnderstandingConfirmed === 'confirmed'
          ? "I confirm that I am ready to start working on CINTRAL."
          : "I need further clarification before moving forward";
    }
          if (registerData.Part1Confirmed === 'confirmed' && registerData.Part2Confirmed === 'confirmed' && registerData.UnderstandingConfirmed === 'confirmed') {
            formData["Role"] = registerData.Role.map(opt => opt.value).join(",");
            formData["Country"] = registerData.Country.map(opt => opt.value).join(",");;

  }
 
    // Capture the clarification if shown (based on Question 2 selection)
    if(	!(registerData.FirstName &&
      registerData.LastName &&
      registerData.Email &&
      registerData.Part1Confirmed === 'confirmed' &&
      registerData.Part2Confirmed === 'confirmed' &&
      registerData.UnderstandingConfirmed === 'confirmed' &&
      registerData.Role?.length > 0 &&
      registerData.Country?.length > 0) ) {
        formData["I need help in understanding the content. I have questions and would like to reach out to the CINTRAL Product Team. Please enter your questions, which will be sent to the CINTRAL Product Team."] = registerData.Clarification;
        query = registerData.Clarification;
      }
 
    const data = {
        "FirstName": registerData.FirstName,
        "LastName": registerData.LastName,
        Email: registerData.Email.trim().toLowerCase(),
        formData: formData,
        question : query? query : null,
        Comments : registerData.Comments ? registerData.Comments : 'NA'
    };
 console.log("payload data", data)
 !(registerData.Part1Confirmed === 'confirmed' &&
 registerData.Part2Confirmed === 'confirmed' &&
 registerData.UnderstandingConfirmed === 'confirmed') ? 
 props.onSubmitQuestion(data) : props.onRegister(data) ;// Dispatch or send to the API
};
 
  // Existing useEffect hooks retained
  useEffect(() => {
    let userDetails = JSON.parse(sessionStorage.getItem('xyzData'));
    let fName = userDetails?.firstName;
    let lName = userDetails?.lastName;
    let email = userDetails?.email;
    
    if (fName) onRegisterChange({ target: { name: 'FirstName', value: fName } });
    if (lName) onRegisterChange({ target: { name: 'LastName', value: lName } });
    if (email) onRegisterChange({ target: { name: 'Email', value: email.toLowerCase() } });
  }, []);
 
  useEffect(() => {
    if (props.optionalData && props.optionalData === 201) {
      sessionStorage.setItem('xyzData', JSON.stringify({ logVal: encryptOrDecryptString('true', 1) }));
    //   registerData.Team === 'NIQ'
    //     ? props.history.push('/auth/registerRequestSubmitted')
    //     : props.history.push('/auth/GFKUserRegisterSubmitted');
    !(registerData.Part1Confirmed === 'confirmed' &&
      registerData.Part2Confirmed === 'confirmed' &&
      registerData.UnderstandingConfirmed === 'confirmed') ?
    props.history.push("/auth/QuestionsSubmitted") :
	props.history.push("/auth/registerCompleted") 
    }
  }, [props.optionalData]);
 
  // Submit button validation
  const isSubmitDisabled = () => {
    if (
      registerData.Part1Confirmed === 'clarification' ||
      registerData.Part2Confirmed === 'clarification' ||
      registerData.UnderstandingConfirmed === 'clarification'
    ) {
      return (
        registerData.FirstName !== '' &&
      registerData.LastName !== '' &&
      registerData.Email &&
      registerData.Clarification !== ''
      );
      
       // Ensure clarification text is filled
    }
    return (
      registerData.FirstName !== '' &&
      registerData.LastName !== '' &&
      registerData.Email &&
      registerData.Part1Confirmed &&
      registerData.Part2Confirmed &&
      registerData.UnderstandingConfirmed &&
      registerData.Role?.length > 0 &&
      registerData.Country?.length > 0
    );
  };
  // useEffect(() => {
	// if(	registerData.FirstName &&
	// 	registerData.LastName &&
	// 	registerData.Email &&
	// 	registerData.Part1Confirmed === 'confirmed' &&
	// 	registerData.Part2Confirmed === 'confirmed' &&
	// 	registerData.UnderstandingConfirmed === 'confirmed' &&
	// 	registerData.Role?.length > 0 &&
	// 	registerData.Country?.length > 0 ) {
	// 		isSubmitorRequest = "request"
	// 	}
  // }, [registerData]);
  const isRequestDisabled = () => {
    if (
      registerData.Part1Confirmed === 'confirmed' &&
      registerData.Part2Confirmed === 'confirmed' &&
      registerData.UnderstandingConfirmed === 'confirmed'
    ) {
	}
    
    return (
      registerData.FirstName &&
      registerData.LastName &&
      registerData.Email &&
      registerData.Part1Confirmed &&
      registerData.Part2Confirmed &&
      registerData.UnderstandingConfirmed &&
      registerData.Role?.length > 0 &&
      registerData.Country?.length > 0
    );
  };
  return (
    <AuthLayout>
      <Card >
	  <CardImg
					variant="top"
					src={image}
					className="mx-auto img-responsive"
				/>
        <CardBody>
          <CardTitle className="text-center mt-4">
            <h3>CINTRAL Account Registration</h3>
          </CardTitle>
          <CardText className="m-sm-3">
            Welcome to CINTRAL registration.
          </CardText>
		  <CardText>
				{/* <h5> */}
			To ensure a smooth and informed experience, all users must first complete a brief orientation by watching two essential training videos. 
			These videos are designed to provide you with the necessary knowledge to navigate and fully utilize the features of CINTRAL. 
			{/* </h5> */}
			</CardText>
			{/* < br /> */}
			<CardText>
			{/* <h5> */}
			Access will be granted only after you have watched both videos and confirmed your completion. 
			We appreciate your commitment to this initial step, as it will empower you to make the most of everything CINTRAL has to offer.
			{/* </h5> */}
			</CardText>
      <span style={{color:'red'}}>*</span><Label>Required</Label>
          <Form autoComplete="off" onSubmit={onRegisterSubmitHandler}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>First Name</Label><span style={{color:'red'}}>*</span>
                  <Input
                    id="RegisterFirstName"
                    type="text"
                    name="FirstName"
                    value={registerData.FirstName}
                    onChange={onRegisterChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Last Name</Label><span style={{color:'red'}}>*</span>
                  <Input
                    id="RegisterLastName"
                    type="text"
                    name="LastName"
                    value={registerData.LastName}
                    onChange={onRegisterChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Email</Label><span style={{color:'red'}}>*</span>
              <Input
                id="RegisterEmail"
                type="email"
                name="Email"
                placeholder="Enter your email"
                value={registerData.Email}
                onChange={onRegisterChange}
                readOnly
                required
              />
            </FormGroup>
            {/* New Questions Added Below */}
            {/* Question 1 */}
            <FormGroup>
              <Label style={{ fontSize: '1.0rem'}}>1. Watch the video <span style={{fontWeight:'bold', marginBottom:'-10px'}}>CINTRAL Training Part 1</span>:</Label> <a href="https://tinyurl.com/5e2yc2rr" style={{color:'blue'}} target='_blank' rel='noopener noreferrer'>https://tinyurl.com/5e2yc2rr</a><span style={{color:'red'}}>*</span>
              <Label style={{display: 'block', color: '#b0b0b0', marginTop:'-10px', marginLeft:'15px'}}>Please confirm that you have watched the video.</Label>
              <div style={radioContainerStyle}>
                <Label style={customRadioLabelStyle}>
                  <Input
                    type="radio"
                    name="Part1Confirmed"
                    value="confirmed"
                    checked={registerData.Part1Confirmed === 'confirmed'}
                    onChange={onRegisterChange}
					style={radioStyle}
                  />
                  I confirm that I have watched the Part 1 video.
                </Label>
				</div>
				<div style={radioContainerStyle}>
                <Label style={customRadioLabelStyle}>
                  <Input
                    type="radio"
                    name="Part1Confirmed"
                    value="clarification"
                    checked={registerData.Part1Confirmed === 'clarification'}
                    onChange={onRegisterChange}
					style={radioStyle}
                  />
                  I need further clarification before moving forward.
                </Label>
              </div>
            </FormGroup>
 
            {/* Question 2 */}
            {registerData.Part1Confirmed === 'confirmed' && (
              <FormGroup>
                <Label style={{fontSize: '1.0rem'}}>2. Watch the video <span style={{fontWeight:'bold'}}>CINTRAL Training Part 2</span>:</Label> <a href='https://tinyurl.com/2s4cpwcp' style={{color:'blue'}} target='_blank' rel='noopener noreferrer'>https://tinyurl.com/2s4cpwcp</a><span style={{color:'red'}}>*</span>
                <Label style={{display: 'block', color: '#b0b0b0', marginTop:'-10px', marginLeft:'15px'}}>Please confirm that you have watched the video.</Label>
                <div style={radioContainerStyle}>
                <Label style={customRadioLabelStyle}>
                    <Input
                      type="radio"
                      name="Part2Confirmed"
                      value="confirmed"
                      checked={registerData.Part2Confirmed === 'confirmed'}
                      onChange={onRegisterChange}
					  style={radioStyle}
                    />
                    I confirm that I have watched the Part 2 video.
                  </Label>
				  </div>
                  <div style={radioContainerStyle}>
                <Label style={customRadioLabelStyle}>
                    <Input
                      type="radio"
                      name="Part2Confirmed"
                      value="clarification"
                      checked={registerData.Part2Confirmed === 'clarification'}
                      onChange={onRegisterChange}
					  style={radioStyle}
                    />
                    I need further clarification before moving forward.
                  </Label>
                </div>
              </FormGroup>
            )}
            {registerData.Part1Confirmed === 'confirmed' && registerData.Part2Confirmed === 'confirmed' && (
              <FormGroup>
                <Label style={{fontSize: '1.0rem'}}>3. Please confirm that you understand the content and everything is clear.:</Label><span style={{color:'red'}}>*</span>
                <Label style={{display: 'block', color: '#b0b0b0', marginTop:'-10px', marginLeft:'15px'}}>Select the option below that best describes your current understanding.</Label>
                <div style={radioContainerStyle}>
                <Label style={customRadioLabelStyle}>
                    <Input
                      type="radio"
                      name="UnderstandingConfirmed"
                      value="confirmed"
                      checked={registerData.UnderstandingConfirmed === 'confirmed'}
                      onChange={onRegisterChange}
					  style={radioStyle}
                    />
                    I confirm that I am ready to start working on CINTRAL.
                  </Label>
				  </div>
                  <div style={radioContainerStyle}>
                <Label style={customRadioLabelStyle}>
                    <Input
                      type="radio"
                      name="UnderstandingConfirmed"
                      value="clarification"
                      checked={registerData.UnderstandingConfirmed === 'clarification'}
                      onChange={onRegisterChange}
					  style={radioStyle}
                    />
                    I need further clarification before moving forward.
                  </Label>
                </div>
              </FormGroup>
            )}
 
            {/* Question 4 */}
            {registerData.Part1Confirmed === 'confirmed' && registerData.Part2Confirmed === 'confirmed' && registerData.UnderstandingConfirmed === 'confirmed'
             && (
              <FormGroup>
                <Label style={{fontSize: '1.0rem'}}>4. Select your role in CINTRAL:</Label><span style={{color:'red'}}>*</span>
                <Select
                isMulti
                isSearchable
                options={RoleOption.map((item) => {
                  return { value: item.Label, label: item.Label };
                })}
                value={registerData.Role}
                onChange={onRegisterChangeRole}
              />
              </FormGroup>
            )}
 
            {/* Question 5 */}
            {registerData.Part1Confirmed === 'confirmed' && registerData.Part2Confirmed === 'confirmed'  && 
            registerData.UnderstandingConfirmed === 'confirmed' && (
              <FormGroup>
                <Label style={{fontSize: '1.0rem'}}>5. Specify the country for which you need access in CINTRAL:</Label><span style={{color:'red'}}>*</span>
                <Select
                isMulti
                isSearchable
                options={CommissioningCountriesOptions.map((item) => {
                  return { value: item.Label, label: item.Label };
                })}
                value={registerData.Country}
                onChange={onRegisterChangeCountry}
              />
              </FormGroup>
            )}
             {registerData.Part1Confirmed === 'confirmed' && registerData.Part2Confirmed === 'confirmed' && registerData.UnderstandingConfirmed === 'confirmed'
             && (
              <FormGroup>
                <Label style={{fontSize: '1.0rem'}}>6. Comments:</Label>
                <Input
                  type="textarea"
                  name="Comments"
                  value={registerData.Comments}
                  onChange={onRegisterChange}
				  // placeholder= 'Please enter your questions...'
                />
              </FormGroup>
            )}
			{(registerData.Part1Confirmed === 'clarification' || registerData.Part2Confirmed === 'clarification' 
      || registerData.UnderstandingConfirmed === 'clarification') && (
              <FormGroup>
                <Label style={{fontSize: '1.0rem'}}>I need help in understanding the content. 
					I have questions and would like to reach out to the CINTRAL Product team.<span style={{color:'red'}}>*</span></Label>
          <Label style={{color: '#b0b0b0', marginTop:'0px', 'padding':'0'}}>Please enter your questions, which will be sent to the CINTRAL Product team.</Label>
                <Input
                  type="textarea"
                  name="Clarification"
                  value={registerData.Clarification}
                  onChange={onRegisterChange}
				  placeholder= 'Please enter your questions...'
                />
              </FormGroup>
            )}
 <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginLeft:'35%'}}>
            <Row>
              <Col>
                
				<Button
                  style={{ float: 'right' }}
                  color="primary"
                  size="lg"
                  disabled={!isSubmitDisabled()}
                >
                 Submit 
                </Button>
				
			
              </Col>
              <Col>
                <Link to="/auth/login" onClick={() => sessionStorage.removeItem('xyzData')} >
                  Back to Login
				  </Link>
              </Col>
            </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </AuthLayout>
  );
};
 
// Redux mapStateToProps and mapDispatchToProps
const mapStateToProps = (state) => ({
pageloaded: state.app.pageloaded,
optionalData: state.app.optionalData,
});
 
const mapDispatchToProps = (dispatch) => ({
  onRegister: (registerData) => dispatch(userActions.register(registerData)),
  onSubmitQuestion: (registerData) => dispatch(userActions.submitQuestions(registerData)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(Register);