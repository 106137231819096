import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faPlus,
  faTimesCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import update from "immutability-helper";
import "./common.css";
import { useDispatch } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  CustomInput,
  Button,
  Container,
  Label,
} from "reactstrap";
import { updateProfile } from "../../redux/actions/currentCostingActions";
import ReturnControls from "./ReturnControl";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";
import logo from "../../assets/img/avatars/modal-logo.png";
import { useContext } from "react";
import { ChangesTracker } from "./CountryMethodologyTabsFinal";
import { current } from "jspreadsheet-ce";

const Start = ({
  profile,
  indxFC,
  methodology,
  indxSM,
  updateProject,
  errorFieldsObj,
  showErrorMessages,
  isEditable,
  setWaveModal,
  disableFields,
}) => {
  var isCategory = false;
  const dispatch = useDispatch();
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const styleModelBody = {
    minHeight: "200px",
  };
  const [modelState, setModalState] = useState(false);
  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  let arr = pacificData.split(",");
  const [pacificMarkets] = useState(arr);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
const categories = useRef();
categories.current = [
  {
    title: "Category 1",
    variables: methodology
      ? methodology.Variables?.filter((v) => v.category)
      : null,
  },
]
  // const [categories, setCategories] = useState([
  //   {
  //     title: "Category 1",
  //     variables: methodology
  //       ? methodology.Variables?.filter((v) => v.category)
  //       : null,
  //   },
  // ]);
  const categoryVariables = useRef();
   categoryVariables.current = methodology ? methodology.Variables?.filter((v) => v.category) : null;

  // const [categoryVariables, setCategoryVariables] = useState(
  //   methodology ? methodology.Variables?.filter((v) => v.category) : null
  // );
  const [lstCopyToCountries, setLstCopyToCountries] = useState([]);
  const [copyData, setCopyData] = useState({});
  const userRecord = useSelector(({ user }) => user.userRecord);
  const {hasDataChanged, deleteId} = useContext(ChangesTracker)

  //new code
  if (methodology.label == "Path Tracking") {
    {
      if (!categoryVariables.current[0].value) {
        categoryVariables.current.forEach((cv, cvindex) => {
          cv.value = cvindex < 3 ? " " : "~"; //e stand for empty
        });
      }
    }
  }
  useEffect(() => {
    if (methodology.label == "Path Tracking") {
      let str = categoryVariables.current[0].value;

      if (str.length > 1) {
        let values = [...categories.current];
        for (let i = 2; i <= str.length; i++) {
          let variables = methodology.Variables?.filter((r) => r.category);
          let title = `Category ${i}`;

          let newcat = { title: title, variables: variables };
          values.push(newcat);
        }
        categories.current = values;
      }
    }
  }, []);

  const _samplingTypeLookUp = [
    "externalSampleSourceSize",
    "internalSampleSourceSize",
    "clientSuppliedSampleSourceSize",
    "otherSampleSourceSize",
  ];

  const _samplingTypeHeaderLookUp = [
    "externalSampleProvider",
    "internalSamplingProvider",
    "clientSuppliedSamplingProvider",
    "otherSamplingProvider",
  ];
  const samplingTypeLookUp = {
    "External Sample Provider": "externalSampleSourceSize",
    "Nielsen Panels": "internalSampleSourceSize",
    "Client Supplied": "clientSuppliedSampleSourceSize",
    Other: "otherSampleSourceSize",
  };

  function CopyRFQData() {
    const countrySpecsClone = _.cloneDeep(profile.CountrySpecs);
    const methodologyClone = _.cloneDeep(
      profile.CountrySpecs[indxFC].MethodologySpecs[indxSM]
    );
    countrySpecsClone
      .filter((fc, indexFC) => indexFC != indxFC)
      .forEach((fc, indexFC) => {
        fc.MethodologySpecs.filter(
          (sm, indexSM) => sm.label == methodologyClone.label
        ).forEach((sm, indexSM) => {
          sm.Variables.forEach((v, indexV) => {
            if (methodologyClone.Variables.find((vv) => vv.label == v.label)) {
              v.value = _.cloneDeep(
                methodologyClone.Variables.filter(
                  (vv) => vv.label == v.label
                )[0].value
              );
              v.disabled = _.cloneDeep(
                methodologyClone.Variables.filter(
                  (vv) => vv.label == v.label
                )[0].disabled
              );
            }
          });
        });
      });

    let profileTemp = update(profile, {
      CountrySpecs: { $set: countrySpecsClone },
    });
    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ...cp };
    });
    dispatch(updateProfile(_.cloneDeep(profileTemp)));
    dispatch(updateProject({ CostingProfiles: tempProfiles }));
  }

  /// TO save the Text of the Category Variables
  function handlecategoryText(e, CVIndex, CIndex, label) {
    var strings = categoryVariables.current[CVIndex].value.split("~");
    strings[CIndex] = e.target.value;
    let finalString = "";
    for (var i = 0; i < strings.length - 1; i++) {
      finalString = finalString + strings[i] + "~";
    }
    categoryVariables.current[CVIndex].value = finalString;
  }

  function handlecategory(e, CVIndex, CIndex, label) {
    var indexValue = categoryVariables.current[CVIndex].value;
    let str1 = indexValue.substr(0, CIndex);
    let str2 = indexValue.substr(CIndex + 1, categories.current.length - CIndex);
    let str = "";
    if (e.target.value == "yes") {
      str = str1 + "y" + str2;
    }
    if (e.target.value == "no") {
      str = str1 + "n" + str2;
    }
    if (e.target.value == " ") {
      str = str1 + " " + str2;
    }
    categoryVariables.current[CVIndex].value = str;
    categoryVariables.current = ([...categoryVariables.current])
  }

  const addCategory = (variables, ind) => {
    categories.current = [
      ...categories.current,
      { title: `Category ${ind + 2}`, variables: variables },
    ];
    let str = " ";
    let str2 = "~";
    categoryVariables.current.forEach((cv, index) => {
      if (index < 3) {
        let str1 = cv.value;
        cv.value = str1 + str;
      } else {
        let str1 = cv.value;
        cv.value = str1 + str2;
      }
    });
  };
  // Ind == Index of the category to be deleted
  const removeCategory = (ind) => {
    let temp = [...categories.current];
    temp.pop();
    categories.current = temp;

    categoryVariables.current.forEach((cv) => {
      let str1 = cv.value;
      cv.value = str1.substr(0, str1.length - 1);
    });
  };
  //if current country not in fielding countries
  const getEmptyRFQ = () => {
    let fieldingCountries = Array.isArray(profile.FieldingCountries)
      ? profile.FieldingCountries.map((fc) => fc.value)
      : profile.FieldingCountries?.split(",");
    if (
      !_.includes(fieldingCountries, profile.CountrySpecs[indxFC].CountryCode)
    ) {
      return true;
    }
    return false;
  };
  const CommissioningCountryCheck = (actualstatus) => {
    let _updatedStatus = actualstatus;
    if (
      profile.Project.CommissioningCountry ==
      profile.CountrySpecs[indxFC].CountryCode
    ) {
      let fieldingCountries = Array.isArray(profile.FieldingCountries)
        ? profile.FieldingCountries.map((fc) => fc.value)
        : profile.FieldingCountries?.split(",");
      if (
        !_.includes(fieldingCountries, profile.Project.CommissioningCountry)
      ) {
        return true;
      } else if (
        _.includes(fieldingCountries, profile.Project.CommissioningCountry)
      ) {
        return false;
      }
    }

    return _updatedStatus;
  };

  const ReturnControl = (v, indexV) => {
    return (
      <ReturnControls
      v= {v}
      methodology ={methodology}
      profile = {profile}
      project = {project}
      indexV ={indexV}
      indxFC = {indxFC}
      indxSM = {indxSM}
      errorFieldsObj ={errorFieldsObj}
      component = "RFQ"
      InfoChange = {InfoChange}
      onBlur={onBlur}
      CommissioningCountryCheck = {CommissioningCountryCheck}
      disableFields = {disableFields}
      >
    
      </ReturnControls>

  )}
  // const ReturnControl = (v, indexV) => {
  //   if (
  //     v.id == "sampleSizeMain" &&
  //     methodology.Variables.some((v) =>
  //       _.includes(_samplingTypeHeaderLookUp, v.id)
  //     )
  //   ) {
  //     v.mandatory = false;
  //   }
  //   if (
  //     (v.id === "numberOfWaves" || v.id === "trackingFrequency") &&
  //     methodology.Variables &&
  //     methodology.Variables.find((v) => v.id == "trackingProject").value == "No"
  //   ) {
  //     return null;
  //   }

  //   if (
  //     methodology?.RFQSchema?.properties[v.id]?.defaultValue &&
  //     v.value != "" &&
  //     !v?.value
  //   ) {
  //     v.value =
  //       v.type === "creatableMulti" &&
  //       v.optionsLabel &&
  //       v.optionsLabel === "Cities"
  //         ? project.CommissioningCountry ===
  //           profile.CountrySpecs[indxFC].CountryCode
  //           ? methodology.RFQSchema.properties[v.id].defaultValue
  //           : ""
  //         : v.type === "integer" ||
  //           v.type === "text" ||
  //           v.type === "textarea" ||
  //           v.type === "multi" ||
  //           v.type === "date"
  //         ? methodology?.RFQSchema?.properties[v.id]?.defaultValue
  //         : v.type === "single"
  //         ? methodology?.RFQSchema?.properties[v.id]?.defaultValue?.value
  //         : v.value;
  //   }

  //   v.disabled = CommissioningCountryCheck(v.disabled);
  //   if (v.type == "integer") {
  //     const isNumberOfWaves = v.id === "numberOfWaves";

  //     return v.id == "incidenceRate" &&
  //       methodology &&
  //       methodology.Variables &&
  //       _.includes(
  //         methodology.Variables.map((v) => v.id),
  //         "externalSampleProvider"
  //       ) &&
  //       methodology.Variables.find((v) => v.id == "externalSampleProvider")
  //         .value != "Applicable" ? null : (
  //       <FormGroup>
  //         <Label>
  //           {v.label + (v.mandatory ? "(*)" : "")}
  //           {v.tooltext && (
  //             <FontAwesomeIcon
  //               title={v.tooltext}
  //               className="align-middle mr-2 addButton"
  //               icon={faInfoCircle}
  //               fixedWidth
  //               style={{ cursor: "pointer" }}
  //             />
  //           )}
  //         </Label>

  //         <AvField
  //           name={"field_" + indexV}
  //           placeholder=""
  //           type="number"
  //           min={v.label == "Incidence Rate Main" ? 1 : isNumberOfWaves ? 2 : 0}
  //           invalid={
  //             v.label == "Incidence Rate Main"
  //               ? parseInt(v.value) > 100 || parseInt(v.value) < 0
  //               : parseInt(v.value) < 0
  //           }
  //           value={v.value || ""}
  //           errorMessage={
  //             isNumberOfWaves && v.value < 2
  //               ? "Number of waves must be more than 1 since it's tracker."
  //               : "field is required"
  //           }
  //           validate={
  //             v.mandatory && !methodology.NotApplicable
  //               ? { required: { value: v.mandatory }, min: { value: 0 } }
  //               : isNumberOfWaves && v.value && v.value < 2
  //               ? { required: { value: true }, min: { value: 2 } }
  //               : {}
  //           }
  //           onChange={(e) => InfoChange(indexV, e.target.value)}
  //           disabled={
  //             v.disabled ||
  //             profile.ProfileStatus == 99 ||
  //             methodology.NotApplicable ||
  //             disableFields ||
  //             (v.id == "sampleSizeMain" &&
  //               pacificMarkets.some(
  //                 (pm) => pm == project.CommissioningCountry
  //               ) &&
  //               methodology.Variables.some((v) =>
  //                 _.includes(_samplingTypeHeaderLookUp, v.id)
  //               ))
  //           }
  //           onBlur={(e) =>
  //             isNumberOfWaves
  //               ? v.value > 1
  //                 ? onBlur(indexV, e.target.value)
  //                 : null
  //               : onBlur(indexV, e.target.value)
  //           }
  //           required={isNumberOfWaves ? true : v.mandatory}
  //         />
  //       </FormGroup>
  //     );
  //   } else if (
  //     v.type == "creatableMulti" &&
  //     v.optionsLabel &&
  //     v.optionsLabel == "Cities"
  //   )
  //     return (
  //       <>
  //         <FormGroup>
  //           <Label>
  //             {v.label + (v.mandatory ? "(*)" : "")}
  //             {v.tooltext && (
  //               <FontAwesomeIcon
  //                 title={v.tooltext}
  //                 className="align-middle mr-2 addButton"
  //                 icon={faInfoCircle}
  //                 fixedWidth
  //                 style={{ cursor: "pointer" }}
  //               />
  //             )}
  //           </Label>
  //           {codeLabels["CitiesOptions"].filter(
  //             (x) => x.Code == profile.CountrySpecs[indxFC].CountryCode
  //           )[0] ? (
  //             <CreatableSelect
  //               className="react-select-container"
  //               classNamePrefix="react-select"
  //               isMulti
  //               isDisabled={
  //                 profile.ProfileStatus == 99 ||
  //                 v.disabled ||
  //                 methodology.NotApplicable ||
  //                 disableFields
  //               }
  //               value={v.value}
  //               onChange={(e) => InfoChange(indexV, e)}
  //               options={codeLabels["CitiesOptions"]
  //                 .filter(
  //                   (x) => x.Code == profile.CountrySpecs[indxFC].CountryCode
  //                 )[0]
  //                 ["Cities"].map((opt) => {
  //                   return {
  //                     value: opt.id,
  //                     label: opt.Label,
  //                   };
  //                 })}
  //             />
  //           ) : (
  //             "No City Found"
  //           )}
  //         </FormGroup>
  //       </>
  //     );
  //   else if (v.type == "multi")
  //     return (
  //       <FormGroup>
  //         <Label>
  //           {v.label + (v.mandatory ? "(*)" : "")}
  //           {v.tooltext && (
  //             <FontAwesomeIcon
  //               title={v.tooltext}
  //               className="align-middle mr-2 addButton"
  //               icon={faInfoCircle}
  //               fixedWidth
  //               style={{ cursor: "pointer" }}
  //             />
  //           )}
  //         </Label>
  //         <Select
  //           name={v.label}
  //           value={v.value}
  //           onChange={(e) => InfoChange(indexV, e)}
  //           className="react-select-container"
  //           classNamePrefix="react-select"
  //           validate={{ required: { value: v.mandatory } }}
  //           required={v.mandatory}
  //           isDisabled={
  //             methodology.NotApplicable || v.disabled || disableFields
  //           }
  //           options={v?.options?.map((option) => {
  //             return {
  //               value: option.value,
  //               label: option.label,
  //             };
  //           })}
  //           isMulti
  //         />
  //       </FormGroup>
  //     );
  //   else if (v.type == "single") {
  //     return (
  //       <FormGroup>
  //         <Label>
  //           {v.label + (v.mandatory ? "(*)" : "")}
  //           {v.tooltext && (
  //             <FontAwesomeIcon
  //               title={v.tooltext}
  //               className="align-middle mr-2 addButton"
  //               icon={faInfoCircle}
  //               fixedWidth
  //               style={{ cursor: "pointer" }}
  //             />
  //           )}
  //         </Label>

  //         <AvField
  //           tag={CustomInput}
  //           name={v.label}
  //           value={v.value || ""}
  //           type="select"
  //           placeholder="please select"
  //           errorMessage="field is required"
  //           validate={
  //             v.mandatory && !methodology.NotApplicable
  //               ? { required: { value: v.mandatory } }
  //               : {}
  //           }
  //           disabled={
  //             profile.ProfileStatus == 99 ||
  //             methodology.NotApplicable ||
  //             disableFields ||
  //             v.disabled
  //           }
  //           onChange={(e) => InfoChange(indexV, e.target.value)}
  //         >
  //           <option value="0"></option>
  //           {v.optionsLabel
  //             ? codeLabels[v.optionsLabel].map((opt) => (
  //                 <option key={opt.Code} value={opt.Code}>
  //                   {opt.Label}
  //                 </option>
  //               ))
  //             : v.options?.map((option) => (
  //                 <option key={option.value} value={option.value}>
  //                   {option.label}
  //                 </option>
  //               ))}
  //         </AvField>
  //       </FormGroup>
  //     );
  //   } else if (v.type == "text")
  //     return (
  //       <FormGroup>
  //         <Label>
  //           {v.label + (v.mandatory ? "(*)" : "")}
  //           {v.tooltext && (
  //             <FontAwesomeIcon
  //               title={v.tooltext}
  //               className="align-middle mr-2 addButton"
  //               icon={faInfoCircle}
  //               fixedWidth
  //               style={{ cursor: "pointer" }}
  //             />
  //           )}
  //         </Label>
  //         <AvField
  //           name={"field_" + indexV}
  //           placeholder=""
  //           type="text"
  //           errorMessage="field is required"
  //           validate={
  //             v.mandatory && !methodology.NotApplicable
  //               ? { required: { value: v.mandatory } }
  //               : {}
  //           }
  //           onChange={(e) => InfoChange(indexV, e.target.value)}
  //           disabled={
  //             profile.ProfileStatus == 99 ||
  //             methodology.NotApplicable ||
  //             disableFields ||
  //             v.disabled
  //           }
  //           value={v.value || ""}
  //         />
  //       </FormGroup>
  //     );
  //   else if (v.type == "textarea")
  //     return v.id == "incidenceRateNote" &&
  //       methodology &&
  //       methodology.Variables &&
  //       _.includes(
  //         methodology.Variables.map((v) => v.id),
  //         "externalSampleProvider"
  //       ) &&
  //       methodology.Variables.find((v) => v.id == "externalSampleProvider")
  //         .value != "Applicable" ? null : (
  //       <FormGroup>
  //         <Label>
  //           {v.label + (v.mandatory ? "(*)" : "")}
  //           {v.tooltext && (
  //             <FontAwesomeIcon
  //               title={v.tooltext}
  //               className="align-middle mr-2 addButton"
  //               icon={faInfoCircle}
  //               fixedWidth
  //               style={{ cursor: "pointer" }}
  //             />
  //           )}
  //         </Label>
  //         <AvField
  //           name={"field_" + indexV}
  //           placeholder=""
  //           type="textarea"
  //           errorMessage="field is required"
  //           validate={
  //             v.mandatory && !methodology.NotApplicable
  //               ? { required: { value: v.mandatory } }
  //               : {}
  //           }
  //           disabled={
  //             profile.ProfileStatus == 99 ||
  //             methodology.NotApplicable ||
  //             disableFields ||
  //             v.disabled
  //           }
  //           onChange={(e) => InfoChange(indexV, e.target.value)}
  //           value={v.value || ""}
  //         />
  //       </FormGroup>
  //     );
  //   else if (v.type == "multi") {
  //     return (
  //       <FormGroup>
  //         <Label>
  //           {v.label + (v.mandatory ? "(*)" : "")}
  //           {v.tooltext && (
  //             <FontAwesomeIcon
  //               title={v.tooltext}
  //               className="align-middle mr-2 addButton"
  //               icon={faInfoCircle}
  //               fixedWidth
  //               style={{ cursor: "pointer" }}
  //             />
  //           )}
  //         </Label>

  //         <Select
  //           name={v.label}
  //           value={v.value}
  //           isDisabled={
  //             profile.ProfileStatus == 99 ||
  //             v.disabled ||
  //             methodology.NotApplicable ||
  //             disableFields
  //           }
  //           onChange={(e) => InfoChange(indexV, e)}
  //           className="react-select-container"
  //           classNamePrefix="react-select"
  //           options={
  //             v.optionsLabel
  //               ? codeLabels[v.optionsLabel].map((opt) => {
  //                   return {
  //                     value: opt.Code,
  //                     label: opt.Label,
  //                   };
  //                 })
  //               : v.options.map((option) => {
  //                   return {
  //                     value: option.value,
  //                     label: option.label,
  //                   };
  //                 })
  //           }
  //           isMulti
  //         />
  //       </FormGroup>
  //     );
  //   } else if (v.type == "header")
  //     return (
  //       <>
  //         <h5 id={"header_" + indexV} className={"header"}>
  //           {v.label}
  //         </h5>
  //       </>
  //     );
  // };

  function onBlur(indexV, value) {
    const variablesClone = _.cloneDeep(
      profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Variables
    );
    const currentVariable = variablesClone[indexV];
    const rfqData = _.cloneDeep(profile.CountrySpecs[indxFC].MethodologySpecs[indxSM]?.RFQData);
    if (currentVariable.id == "numberOfWaves" && profile.WaveSpecs?.length) {
      if(rfqData && (!rfqData.numberOfWaves || rfqData.numberOfWaves && rfqData.numberOfWaves != value))
        setWaveModal(true);
    }

    if (_samplingTypeLookUp.some((sm) => sm == currentVariable.id)) {
      let total = 0;
      _samplingTypeLookUp.forEach((slook) => {
        total =
          variablesClone.find((v) => v.id == slook) &&
          variablesClone.find((v) => v.id == slook).value
            ? parseInt(variablesClone.find((v) => v.id == slook).value) + total
            : total;
      });

      variablesClone[
        variablesClone.findIndex((v) => v.id == "sampleSizeMain")
      ].value = total;

            let profileTemp = _.cloneDeep(profile)
            profileTemp.CountrySpecs[indxFC].MethodologySpecs[indxSM].Variables = variablesClone
            const tempProfiles = project.CostingProfiles.map(cp => {
                if (cp.id == profileTemp.id)
                    return { ...profileTemp }
                return { ...cp }
            });
      dispatch(updateProfile(_.cloneDeep(profileTemp)));
      dispatch(updateProject({ CostingProfiles: _.cloneDeep(tempProfiles) }));
    }
  }

  function InfoChange(indexV, value) {
    const variablesClone = _.cloneDeep(
      profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Variables
    );
    const RFQData = profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].RFQData
    const currentVariable = variablesClone[indexV];

    if (value == null) {
      value =
        currentVariable.type === "creatableMulti" ||
        currentVariable.type === "multi"
          ? []
          : value;
    }
    currentVariable["value"] = value;

    if (currentVariable.id == "respondentParticipation") {
      variablesClone.filter(
        (hidVar) => hidVar.id == "fieldworkLength"
      )[0].value = parseInt(value * 2.5);
    }

    if (currentVariable.label == "Sample size per scenario per option")
      variablesClone.filter(
        (hidVar) => hidVar.id == "totalSamplePerCountryPerOPtion"
      )[0].value =
        (parseInt(
          variablesClone.filter(
            (hidVar) => hidVar.id == "numberofTestScenario"
          )[0].value
        ) +
          parseInt(
            variablesClone.filter(
              (hidVar) => hidVar.id == "numberofControlScenario"
            )[0].value
          )) *
        value;
    if (currentVariable.label == "Type Of Venue") {
      //make it to id, not sure why id isn't working
      let typeOfChannel = variablesClone.find((vc) => vc.id == "typeOfChannel");
      typeOfChannel.options =
        value == "Store"
          ? [
              { label: "Hypermarket", value: "Hypermarket" },
              { label: "Supermarket", value: "Supermarket" },
              { label: "Convenience", value: "Convenience" },
              { label: "Discounter", value: "Discounter" },
              { label: "Traditional Trade", value: "Traditional Trade" },
              { label: "On-trade", value: "On-trade" },
              { label: "Gas/Petrol Station", value: "Gas/Petrol Station" },
              { label: "Other", value: "Other" },
            ]
          : value == "Restaurants/pubs"
          ? [
              { label: "Restaurant", value: "Restaurant" },
              { label: "Pub", value: "Pub" },
            ]
          : value == "Leaflets/App/Web"
          ? [{ label: "Leaflet", value: "Leaflet" }]
          : [];
    }
    variablesClone.forEach((v, indexV) => {
      if (
        v.dependentOn &&
        v.dependentOn.fields.find((f) => f.fieldName == currentVariable.label)
      ) {
        let op = v.dependentOn.andor == "OR" ? "||" : "&&";
        let expression = "";
        v.dependentOn.fields.forEach((f, indexF) => {
          let fieldVal =
            f.fieldName != currentVariable.label
              ? variablesClone.filter((v) => v.label == f.fieldName)[0].value
              : currentVariable["value"];
          if (Array.isArray(fieldVal)) {
            let tf = f.fieldValue;
            if (
              f.fieldValue.length > 0 &&
              typeof f.fieldValue[0] === "object" &&
              f.fieldValue[0].label
            ) {
            }
            let tempBool = false;
            if (
              variablesClone.filter((v) => v.label == f.fieldName)[0]["value"]
            ) {
              tempBool =
                variablesClone
                  .filter((v) => v.label == f.fieldName)[0]
                  ["value"].filter((x) => x.label == f.fieldValue).length > 0;
            } else if (currentVariable["value"]) {
              tempBool =
                currentVariable["value"].filter((x) => x.label == f.fieldValue)
                  .length > 0;
            }
            expression +=
              tempBool +
              f.operator +
              "true" +
              (indexF != v.dependentOn.fields.length - 1 ? op : "");
          } else {
            expression +=
              getChangedValue(fieldVal) +
              f.operator +
              getChangedValue(f.fieldValue) +
              (indexF != v.dependentOn.fields.length - 1 ? op : "");
          }
        });

        let enabled = eval(expression);
        if (enabled) {
          v.disabled = false;
        } else{
          v.disabled = true;
          deleteId(v.id, indxFC, indxSM)
        }
      }
    });

    const hasChanged = hasDataChanged(RFQData, variablesClone[indexV], indxFC, indxSM)
    isEditable(hasChanged)
    let profileTemp = _.cloneDeep(profile);
    profileTemp.CountrySpecs[indxFC].MethodologySpecs[indxSM].Variables =
      variablesClone;
    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ...profileTemp };
      return { ...cp };
    });
    dispatch(updateProfile(_.cloneDeep(profileTemp)));
    dispatch(updateProject({ CostingProfiles: _.cloneDeep(tempProfiles) }));
  }

  function getChangedValue(value) {
    let rValue = null;
    if (isNaN(value)) rValue = "'" + value + "'";
    else rValue = value;
    return rValue;
  }

  console.log("methodologyyyyyyy", methodology)

  return (
    <React.Fragment>
      {methodology.Variables?.length !=
        methodology.CostingOptions[0]?.Variables?.length && methodology.Code !=  
        "SM000041" && (
        <Card className="ml-2 mr-2">
          <CardHeader>
            <Row>
              <Col style={{ display: "flex", justifyContent: "space-between" }}>
                <CardTitle className="mb-0">RFQ Form</CardTitle>
                {profile.CountrySpecs && profile.CountrySpecs.length > 1 && (
                  <FontAwesomeIcon
                    title="Copy Data To Other Markets"
                    className="align-middle mr-2 addButton"
                    icon={!disableFields ? faCopy : ""}
                    fixedWidth
                    onClick={() => {
                      setModalState(true);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={12} className={"countryTabs tab-vertical"}>
                <div className={"tab"}>
                  <Container fluid className="p-1">
                    <AvForm
                      encType="multipart/form-data"
                      id="RFQFinalForm"
                      onSubmit={(e, err) =>
                        showErrorMessages(e, err, "RfqformErrors")
                      }
                    >
                      <Row>
                        {methodology && methodology.Variables
                          ? methodology.Variables.map((v, indexV) => {
                              if (
                                !v.Default &&
                                !v.selected &&
                                (!v.disabled || getEmptyRFQ()) &&
                                v.isNewSection &&
                                methodology.Variables.filter(
                                  (x) =>
                                    !x.selected &&
                                    x.sectionTitle == v.sectionTitle
                                ).length > 0
                              )
                                return (
                                  <>
                                    <Col md={"12"}>
                                      <h5
                                        id={"header_" + indexV}
                                        className={"header"}
                                      >
                                        <div>{v.sectionTitle}</div>
                                      </h5>
                                      {v.instructions && (
                                        <h6
                                          id={"header_i_" + indexV}
                                          className={"header_instructions"}
                                        >
                                          <b>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: v.instructions,
                                              }}
                                            ></div>
                                          </b>
                                        </h6>
                                      )}
                                    </Col>
                                    {_.includes(_samplingTypeLookUp, v.id) &&
                                      !_.includes(
                                        _.head(
                                          methodology.CostingOptions
                                        )?.Variables.map((coV) => coV.id),
                                        v.id
                                      ) && (
                                        <>
                                          <Col
                                            md={v.width || "4"}
                                            key={
                                              "c_" +
                                              indxFC +
                                              "_m_" +
                                              indxSM +
                                              "_co_" +
                                              indexV
                                            }
                                          >
                                            {ReturnControl(v, indexV)}
                                          </Col>
                                          <Col md={4}></Col>
                                        </>
                                      )}
                                    {!_.includes(_samplingTypeLookUp, v.id) &&
                                      !_.includes(
                                        _.head(
                                          methodology.CostingOptions
                                        )?.Variables.map((coV) => coV.id),
                                        v.id
                                      ) && (
                                        <Col
                                          md={v.width || "4"}
                                          key={
                                            "c_" +
                                            indxFC +
                                            "_m_" +
                                            indxSM +
                                            "_co_" +
                                            indexV
                                          }
                                        >
                                          {ReturnControl(v, indexV)}
                                        </Col>
                                      )}
                                  </>
                                );
                              else if (
                                (v.selected || v.Default) &&
                                v.isNewSection &&
                                methodology.Variables.filter(
                                  (x) =>
                                    !x.selected &&
                                    x.sectionTitle == v.sectionTitle
                                ).length > 0 &&
                                !v.category
                              )
                                return (
                                  <Col md={"12"}>
                                    <h5
                                      id={"header_" + indexV}
                                      className={"header"}
                                    >
                                      <div>{v.sectionTitle}</div>
                                      {v.sectionTitle ==
                                        "Quota Specification" && <></>}
                                    </h5>
                                  </Col>
                                );
                              else if (
                                !v.selected &&
                                !v.disabled &&
                                !v.Default &&
                                !v.isNewSection &&
                                !v.category
                              )
                                return _.includes(_samplingTypeLookUp, v.id)
                                  ? !_.includes(
                                      _.head(
                                        methodology.CostingOptions
                                      )?.Variables.map((coV) => coV.id),
                                      v.id
                                    ) && (
                                      <>
                                        <Col
                                          md={v.width || "4"}
                                          key={
                                            "c_" +
                                            indxFC +
                                            "_m_" +
                                            indxSM +
                                            "_co_" +
                                            indexV
                                          }
                                        >
                                          {ReturnControl(v, indexV)}
                                        </Col>
                                        <Col md={4}></Col>
                                      </>
                                    )
                                  : !_.includes(
                                      _.head(
                                        methodology.CostingOptions
                                      )?.Variables.map((coV) => coV.id),
                                      v.id
                                    ) && (
                                      <Col
                                        md={v.width || "4"}
                                        key={
                                          "c_" +
                                          indxFC +
                                          "_m_" +
                                          indxSM +
                                          "_co_" +
                                          indexV
                                        }
                                      >
                                        {ReturnControl(v, indexV)}
                                      </Col>
                                    );
                              else if (
                                methodology.label == "Path Tracking" &&
                                v.category &&
                                !isCategory
                              ) {
                                isCategory = true;
                                return (
                                  <>
                                    <Table
                                      size="sm"
                                      className="break-before"
                                      bordered
                                      style={{
                                        backgroundColor: "white",
                                        maxWidth: "100%",
                                        width: "100%",
                                      }}
                                    >
                                      <thead>
                                        <tr>
                                          <td>Categories </td>
                                          {categories.current.map((ct, CIndex) => {
                                            return (
                                              <td>
                                                {ct.title}
                                                {categories.current.length > 1 &&
                                                  CIndex ==
                                                  categories.current.length - 1 && (
                                                    <FontAwesomeIcon
                                                      title="Delete Category"
                                                      className="align-middle mr-2 addButton"
                                                      icon={faTimesCircle}
                                                      fixedWidth
                                                      style={{
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                      }}
                                                      onClick={() => {
                                                        alert(
                                                          "Delete Category"
                                                        );

                                                        removeCategory(CIndex);
                                                      }}
                                                    />
                                                  )}

                                                {CIndex ==
                                                  categories.current.length - 1 && (
                                                  <FontAwesomeIcon
                                                    title="Add Category"
                                                    className="align-middle mr-2 addButton"
                                                    icon={faPlus}
                                                    fixedWidth
                                                    style={{
                                                      cursor: "pointer",
                                                      marginLeft:
                                                      categories.current.length < 2
                                                          ? "20px"
                                                          : "",
                                                    }}
                                                    onClick={() => {
                                                      alert("Add New Category");

                                                      addCategory(
                                                        ct.variables,
                                                        CIndex
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {/* {console.log("categories,categoryVariables", categories, categoryVariables>current)}
                                        {console.log("categories,categoryVariables", categories, categoryVariables>current)} */}
                                        {categories.current[0]["variables"].map(
                                          (ct, ctIndex) => {
                                            return (
                                              <tr>
                                                <td
                                                  style={{ maxWidth: "150px" }}
                                                >
                                                  {ct.label}
                                                </td>
                                                {categories.current.map((c, i) => {
                                                  return ctIndex < 3 ? (
                                                    <td>
                                                      <AvForm>
                                                        <SelectWrapper
                                                          disabled={
                                                            disableFields
                                                          }
                                                          type="select"
                                                          value={
                                                            categoryVariables.current[
                                                              ctIndex
                                                            ].value[i] == "y"
                                                              ? "yes"
                                                              : categoryVariables.current[
                                                                  ctIndex
                                                                ].value[i] ==
                                                                "n"
                                                              ? "no"
                                                              : " "
                                                          }
                                                          name="demo"
                                                          onChange={(e) => {
                                                            handlecategory(
                                                              e,
                                                              ctIndex,
                                                              i,
                                                              ct.label
                                                            );
                                                          }}
                                                        >
                                                          <option value=" "></option>
                                                          <option value="yes">
                                                            Yes
                                                          </option>
                                                          <option value="no">
                                                            No
                                                          </option>
                                                        </SelectWrapper>
                                                      </AvForm>
                                                    </td>
                                                  ) : (
                                                    <td>
                                                      <AvForm>
                                                        <AvField
                                                          type="textarea"
                                                          disabled={
                                                            disableFields
                                                          }
                                                          name="demo"
                                                          value={
                                                            categoryVariables.current[
                                                              ctIndex
                                                            ].value.split("~")[
                                                              i
                                                            ]
                                                          }
                                                          onChange={(e) => {
                                                            handlecategoryText(
                                                              e,
                                                              ctIndex,
                                                              i,
                                                              ct.label
                                                            ); // To Save Text
                                                          }}
                                                        ></AvField>
                                                      </AvForm>
                                                    </td>
                                                  );
                                                })}
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </Table>
                                  </>
                                );
                              }
                            })
                          : getEmptyRFQ()
                          ? "Fielding is not applicable for this country. Hence can't use RFQ form"
                          : ""}
                      </Row>
                    </AvForm>
                  </Container>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}

      <Modal
        isOpen={modelState}
        toggle={() => setModalState(!modelState)}
        centered
        size={"xm"}
      >
        <ModalHeader toggle={() => setModalState(!modelState)}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
          <span className="modaltitle p-0">
            {"Copying RFQ data..."}
          </span>
        <ModalBody className="m-3" style={{ minHeight: "60px" }}>
          <Row>
            <Col md={12}>
              <h4>Do you want to copy RFQ data to other applicable markets?</h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              CopyRFQData();
              setModalState(false);
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setModalState(false)}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default Start;
